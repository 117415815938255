<template>
  <div>
    <div class="top">
      <div class="topTop">
        自助菜单
        <el-switch style="display: block" v-model="selfMenu"> </el-switch>
      </div>
      <div class="topBot">开启后，买家将在微商城的在线客服咨询中看到自助菜单</div>
    </div>
    <div class="bot">
      <Phone
        type="selfMenu"
        :obj="chooseThat"
        :selfMenuList="selfMenuList"
        :commonProblem="commonProblem"
      ></Phone>
      <div class="botRight">
        <div class="botRightTop">
          <template v-for="(item, index) in selfMenuList" :key="index">
            <div
              @click="chooseThatFn(item)"
              :class="['selfMenuItem', chooseThat.type == item.type ? 'selfMenuItemActive' : '']"
            >
              {{ item.name }}
              <i class="iconfont icon-guanbi" @click.stop="delSelfMenu(index)"></i>
            </div>
          </template>
          <el-popover
            v-model:visible="allSelfMenuFlag"
            placement="bottom-start"
            :width="240"
            trigger="click"
          >
            <div class="selfMenuList">
              <template v-for="(item, index) in allSelfMenu" :key="index">
                <div class="selfMenuItem" @click="addMenuFn(item)">{{ item.name }}</div>
              </template>
            </div>
            <template #reference>
              <div class="textBtn">添加菜单</div>
            </template>
          </el-popover>
        </div>
        <div class="botRightMid" v-if="chooseThat.type">
          <div class="menuTitle">
            {{
              chooseThat.type == 'order'
                ? '订单查询'
                : chooseThat.type == 'question'
                ? '常见问题'
                : chooseThat.type == 'links'
                ? '链接'
                : chooseThat.type == 'coupon'
                ? '优惠券'
                : chooseThat.type == 'likes'
                ? '猜你喜欢'
                : ''
            }}
          </div>
          <div class="menuContent">
            <div class="itemBox">
              <div class="left">菜单名称：</div>
              <div class="right">
                <el-input
                  type="text"
                  placeholder="请输入菜单名称"
                  v-model="chooseThat.menu_name"
                  maxlength="6"
                  show-word-limit
                ></el-input>
                <div
                  class="txtTips"
                  v-if="chooseThat.type == 'question' || chooseThat.type == 'likes'"
                >
                  {{
                    chooseThat.type == 'question'
                      ? '买家可以通过菜单获取你设置的常见问题'
                      : chooseThat.type == 'likes'
                      ? '结合用户行为和店铺上新、热销、活动等维度发送商品推荐，最多推荐9款。（左图仅为商品推荐示例，实际推荐以线上为准）'
                      : ''
                  }}
                </div>
              </div>
            </div>
            <div class="itemBox" v-if="chooseThat.type == 'links'">
              <div class="left">链接：</div>
              <div class="right">
                <el-input
                  style="width: 400px"
                  type="text"
                  placeholder="请输入链接地址"
                  v-model="chooseThat.links_url"
                ></el-input>
              </div>
            </div>
            <div class="itemBox" v-if="chooseThat.type == 'coupon'">
              <div class="left1">推荐方式：</div>
              <div class="right1">
                <el-radio v-model="chooseThat.selection" label="0">智能选券</el-radio>
                <div class="txtTips">
                  根据用户画像，最多选择 4
                  张优惠券进行推荐。（左图仅为智能选券示例，实际推荐以线上为准）
                </div>
                <el-radio v-model="chooseThat.selection" label="1">自定义选券</el-radio>
                <div class="txtTips">根据商家设置，最多选择 4 张优惠券进行推荐。</div>
              </div>
            </div>
            <div class="itemBox" v-if="chooseThat.type == 'coupon' && chooseThat.selection == '1'">
              <div class="left">自定义选券：</div>
              <div class="right" style="line-height: 40px">
                <div class="textBtn" @click="addCouponListFlag">添加优惠券</div>
                <div class="couponList">
                  <template v-for="(item, index) in selfConList" :key="index">
                    <div class="couponListItem">
                      <p>
                        {{ item.name }}（{{
                          item.type == 1 ? '满减券  ' : item.type == 2 ? '立减券  ' : '满折券  '
                        }}
                        {{ item.rule }}）
                      </p>
                      <i @click="delChooseCon(index)" class="iconfont icon-guanbi"></i>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="itemBox" v-if="chooseThat.type == 'coupon'">
              <div class="left1">更多设置：</div>
              <div class="right1">
                <el-checkbox v-model="chooseThat.hide_coupon">隐藏已抢完的优惠券</el-checkbox>
                <div class="txtTips">当页面无可显示的优惠券时，提示暂无可领取的优惠券。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Preservation @preservation="preservation"></Preservation>
    <el-dialog title="优惠券" v-model="couponListFlag" width="80%">
      <div class="searchBox">
        <el-input v-model="couponName" placeholder="请输入优惠券名称"></el-input>
        <el-button type="primary" @click="searchCon">搜索</el-button>
      </div>
      <el-table
        :data="couponList"
        style="width: 100%"
        :header-cell-style="{ 'background-color': '#F8F9FA' }"
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <No />
        </template>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="优惠券名称" align="center"> </el-table-column>
        <el-table-column label="优惠券类型" align="center">
          <template v-slot="{ row }">
            {{ row.type == 1 ? '满减券' : row.type == 2 ? '立减券' : '满折券' }}
          </template>
        </el-table-column>
        <el-table-column prop="rule" label="优惠规则" align="center"> </el-table-column>
        <el-table-column prop="title" label="用户资格" align="center"></el-table-column>
        <el-table-column prop="each_limit" label="每人限领" align="center"></el-table-column>
        <el-table-column prop="get_number" label="领取量" align="center"></el-table-column>
        <el-table-column prop="use_number" label="使用数量" align="center"></el-table-column>
        <el-table-column prop="number" label="发放数量" align="center"></el-table-column>
        <el-table-column label="活动状态" align="center">
          <template v-slot="{ row }">
            <span v-if="row.status">{{
              row.activity_status == 0 ? '未开始' : row.activity_status == 1 ? '进行中' : '已结束'
            }}</span>
            <span v-else>已停止</span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" align="center" width="200">
          <template v-slot="{ row }">
            {{ getDate(row.end_time) }}
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" @againGetInfo="againGetInfo"></Paging>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="couponListFlag = false">取 消</el-button>
          <el-button type="primary" @click="sureChoose">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, ref } from 'vue'
import { post } from '@/util/axios.js'
import { api } from '@/util/api.js'
import { getDate } from '@/util/common.js'
import Phone from '@/components/phone'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
export default {
  components: {
    Phone
  },
  async setup() {
    const selfMenu = ref(false)
    // 提交时使用的对象
    let selfSet = reactive({})
    // 获取自助菜单的设置
    const selfMenuList = reactive([])
    // 我的优惠券列表
    const selfConList = ref([])
    await post(api.getSelfServiceMenu).then((res) => {
      if (res.code == 0) {
        if (res.result) {
          selfMenu.value = res.result.is_open ? true : false
          let data = res.result.config_data
          for (const key in data) {
            let obj = _.cloneDeep(data[key])
            obj.type = key
            obj.name =
              key == 'coupon'
                ? '优惠券'
                : key == 'likes'
                ? '猜你喜欢'
                : key == 'links'
                ? '链接'
                : key == 'order'
                ? '订单查询'
                : key == 'question'
                ? '常见问题'
                : ''
            if (key == 'coupon') {
              if (data[key].securities_selection == '1') {
                obj.selection = '0'
              } else {
                obj.selection = '1'
                obj.coupon_id.map(async (item1, index1) => {
                  await post(api.getCouponInfo, {
                    id: item1
                  }).then((res) => {
                    selfConList.value.push(res.result)
                  })
                })
              }
              obj.coupon_id = []
              obj.hide_coupon = obj.hide_coupon == '1' ? true : false
              delete obj.securities_selection
              delete obj.diy_selection
            }
            selfMenuList.push(obj)
          }
        }
      }
    })
    // 获取所有自助菜单列表
    let allSelfMenu = reactive([])
    let allSelfMenuFlag = ref(false)
    await post(api.getCostomerMenu).then((res) => {
      if (res.code == 0) {
        allSelfMenu = res.result
      }
    })
    // 获取常见问题列表
    let commonProblem = reactive([])
    await post(api.getProblem).then((res) => {
      if (res.code == 0) {
        commonProblem = res.result.list
      }
    })
    // 获取我的菜单配置
    let chooseThat = ref({})
    const addMenuFn = (item) => {
      let res = selfMenuList.find((item1) => item1.type == item.type)
      if (res !== undefined) {
        ElMessage.warning('每个菜单只可以添加一次')
      } else {
        item.menu_name = ''
        if (item.type == 'links') {
          item.links_url = ''
        }
        if (item.type == 'coupon') {
          item.selection = '0'
          item.hide_coupon = true
          item.coupon_id = []
        }
        selfMenuList.push(item)
        chooseThat.value = item
        allSelfMenuFlag.value = false
      }
    }
    // 选中菜单
    const chooseThatFn = (item) => {
      chooseThat.value = item
    }
    // 保存所有设置
    const preservation = () => {
      let flag = true
      // 循环菜单 ->挑出不合规范的位置
      for (let i = 0; i < selfMenuList.length; i++) {
        if (selfMenuList[i].menu_name == '') {
          chooseThat.value = selfMenuList[i]
          ElMessage.warning(`请填写${selfMenuList[i].name}显示的菜单名称`)
          flag = false
          break
        }
        if (selfMenuList[i].type == 'links' && selfMenuList[i].links_url == '') {
          chooseThat.value = selfMenuList[i]
          ElMessage.warning(`请填写${selfMenuList[i].name}要跳转的地址`)
          flag = false
          break
        }
        if (selfMenuList[i].type == 'coupon') {
          if (selfMenuList[i].selection == '1') {
            selfMenuList[i].coupon_id = []
            selfConList.value.map((item) => selfMenuList[i].coupon_id.push(item.id))
            if (selfMenuList[i].coupon_id.length <= 0) {
              chooseThat.value = selfMenuList[i]
              ElMessage.warning(`请选择要推送的优惠券`)
              flag = false
              break
            }
          }
        }
      }
      if (!flag) return
      // 当数据填写符合规则后
      selfSet.is_open = selfMenu.value ? 1 : 0
      selfSet.config = {}
      _.cloneDeep(selfMenuList).map((item) => {
        let key = item.type
        selfSet.config[key] = item
        if (item.type == 'coupon') {
          console.log(item, 'sdf')
          if (item.selection == '0') {
            selfConList.value = []
          }
          item.securities_selection = item.selection == '0' ? 1 : 0
          item.diy_selection = item.selection == '1' ? 1 : 0
          item.hide_coupon = item.hide_coupon ? 1 : 0
          delete item.selection
        }
        delete item.type
        delete item.name
      })
      post(api.setSelfServiceMenu, selfSet).then((res) => {
        if (res.code === 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    // 删除该自定义菜单
    const delSelfMenu = (index) => {
      selfMenuList.splice(index, 1)
      chooseThat.value = {}
      chooseThat.value.menu_name = ''
    }
    // 优惠券弹框
    const couponListFlag = ref(false)
    const couponName = ref('')
    let couponList = ref([])
    const chooseThere = ref([])
    const page = ref(1)
    const rows = ref(10)
    const total = ref(0)
    const searchList = () => {
      post(api.couponList, {
        page: page.value,
        rows: rows.value,
        name: couponName.value,
        status: 2
      }).then((res) => {
        if (res.code == 0) {
          couponList.value = res.result.list
          total.value = res.result.total_number
        }
      })
    }
    const addCouponListFlag = () => {
      searchList()
      couponListFlag.value = true
    }
    const handleSelectionChange = (val) => {
      chooseThere.value = val
    }
    const againGetInfo = (obj) => {
      if (obj.page) {
        page.value = obj.page
      }
      if (obj.row) {
        rows.value = obj.row
      }
      searchList()
    }
    const sureChoose = () => {
      if (selfConList.value.length + chooseThere.value.length > 4) {
        ElMessage.warning('最多只可以选择4张优惠券')
        return
      }
      selfConList.value.push(...chooseThere.value)
      couponListFlag.value = false
    }
    //删除某一项选择的优惠券
    const delChooseCon = (index) => {
      selfConList.value.splice(index, 1)
    }
    // 搜索优惠券
    const searchCon = () => {
      page.value = 1
      searchList()
    }
    return {
      // 处理时间
      getDate,
      // 是否开启自助菜单
      selfMenu,
      // 个人自助菜单配置
      selfSet,
      // 所有的自助菜单
      allSelfMenu,
      // 是否显示所有菜单
      allSelfMenuFlag,
      // 常见问题列表
      commonProblem,
      // 自己的菜单配置
      selfMenuList,
      // 选中的菜单
      chooseThat,
      // 选中的菜单Fn
      chooseThatFn,
      // 添加菜单
      addMenuFn,
      // 保存设置
      preservation,
      // 点击删除该菜单
      delSelfMenu,
      // 点击开启优惠券弹框
      addCouponListFlag,
      handleSelectionChange,
      againGetInfo,
      delChooseCon,
      // 优惠券弹框标杆
      couponListFlag,
      // 优惠券列表
      couponList,
      total,
      // 自己确认的优惠券
      selfConList,
      // 确认选择的优惠券
      sureChoose,
      // 搜索的优惠券名称
      couponName,
      // 搜索优惠券
      searchCon
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  padding: 20px;
  background-color: #f7f8fa;
  .topTop {
    display: flex;
    align-items: center;
    font-size: 15px;
    .el-switch {
      margin-left: 10px;
    }
  }
  .topBot {
    margin-top: 16px;
    font-size: 14px;
    color: #999;
  }
}
.bot {
  margin-top: 20px;
  margin-bottom: 70px;
  display: flex;
  .botRight {
    flex: 1;
    margin-left: 20px;
    .botRightTop {
      display: flex;
      align-items: center;
      .textBtn {
        line-height: 32px;
        margin: 0 20px 10px 0;
      }
      .selfMenuItem {
        user-select: none;
        border-radius: 2px;
        padding: 8px 24px;
        text-align: center;
        border: 1px solid #dddee0;
        color: #323233;
        margin: 0 20px 10px 0;
        font-size: 14px;
        line-height: 1;
        cursor: pointer;
        position: relative;
        &:hover i {
          display: inline-block;
        }
        & > i {
          display: none;
          border-radius: 50%;
          position: absolute;
          top: -8px;
          right: -8px;
          background-color: #fff;
          color: #646566;
        }
      }
      .selfMenuItemActive {
        border-color: #155bd4;
      }
    }
    .botRightMid {
      .menuTitle {
        margin-bottom: 16px;
        padding: 10px 12px;
        min-height: 40px;
        box-shadow: 0 1px 0 0 #ebedf0;
      }
      .menuContent {
        .itemBox {
          display: flex;
          margin-bottom: 20px;
          .left {
            width: 120px;
            flex-shrink: 0;
            text-align: right;
            line-height: 40px;
            margin-right: 20px;
          }
          .left1 {
            width: 120px;
            flex-shrink: 0;
            text-align: right;
            margin-right: 20px;
          }
          .right {
            :deep(.el-input) {
              width: 200px;
              flex-shrink: 0;
            }
            .txtTips {
              margin-top: 15px;
              font-size: 12px;
            }
            .couponList {
              .couponListItem {
                width: 400px;
                line-height: 30px;
                background-color: #fff;
                padding: 4px 10px;
                border: 1px solid #dddee0;
                position: relative;
                p {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                i {
                  display: none;
                  position: absolute;
                  top: -10px;
                  right: -10px;
                  font-size: 20px;
                  cursor: pointer;
                }
              }
              .couponListItem:not(:first-child) {
                margin-top: 10px;
              }
              .couponListItem:hover i {
                display: inline-block;
              }
            }
          }
          .right1 {
            .txtTips {
              margin-top: 10px;
            }
            .txtTips:not(:last-of-type) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

.selfMenuList {
  .selfMenuItem {
    width: 100%;
    line-height: 32px;
    padding-left: 16px;
    cursor: pointer;
    &:hover {
      background-color: #edf4ff;
    }
  }
}
.searchBox {
  display: flex;
  justify-content: right;
  align-items: center;
  .el-input {
    max-width: 300px;
  }
}
</style>
